<ng-template [nzModalTitle]>
    <div class="flex-space-between">
        <div>Duplicate Order</div>
    </div>
</ng-template>
<div class="separator h"></div>
<form nz-form [formGroup]="formInput" class="form">
    <div *ngFor="let key of ['date']">
        <nz-form-item>
            <nz-form-control>
                <div class="form-label-v2">Pickup Date</div>
                <nz-date-picker *ngIf="key == 'date'" style="width: 100%;"
                    [formControlName]="key" nzFormat="MM-dd-yyyy">
                </nz-date-picker>
            </nz-form-control>
        </nz-form-item>
    </div>
</form>
<div form-footer [onProgress]="onProgress" 
  [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress"
  [canClickCancel]="!onProgress" labelOK="Clone Order"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>