<div *ngIf="shouldShowFirstLoading" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div *ngIf="!shouldShowFirstLoading">
  <div class="dashboard-child-container bg-white no-padding ">
    <div class="f20 font-semibold bottom20" style="padding: 20px;">
      <div class="flex-column">
        <div class="flex">
          <div>Order: <span class="warp-id left5">{{showOrderCode(model)}}</span></div>
          <div class="left10 f15" style="font-weight: normal;">
            <copy-text text="{{ getTrackingLink(model) }}" tooltip="Copy tracking URL"></copy-text>
          </div>
          <div *ngIf="txtCreateMode" class="f16m left10 right10" style="align-self: center;">({{txtCreateMode}})</div>
          <div *ngIf="shouldShowHistory">
            <div class="f16m left10">
            <i class="clickable" class="right10"
              nz-icon nzType="history" nzTheme="outline"
              nz-tooltip nzTooltipTitle="View History" (click)="goToHistory(model, routeAdminShipmentList)"></i>
            </div>
          </div>
          <div *ngIf="isReviewed">
            <div class="f16m left10">
              <i nz-icon nzType="check-circle" nzTheme="outline" class="right10"></i>
              Reviewed
            </div>
          </div>
          <div *ngIf="needReview && !isOnHold">
            <a (click)="addShipmentQueueConfirmation()" class="f16m left10 right10">
              <i *ngIf="!onProgress" nz-icon nzType="check-circle" nzTheme="outline" class="right5"></i>
              <i *ngIf="onProgress" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
              Ready for shipment Queue
            </a>
          </div>
          <div *ngIf="showEvents">
            <span class="clickable f14 regular right10" (click)="showOrderEventHistory()">
              <span nz-icon nzType="compass" nzTheme="outline"></span>
            </span>
          </div>
          <div>
            <span class="clickable f14 regular right10" (click)="onTagsBtn()">
              <span nz-icon nzType="tags" nzTheme="outline"></span>
            </span>
            <order-tag-list *ngIf="model?.tags" [tags]="model.tags"></order-tag-list>
          </div>
          <a *ngIf="isExfreightShipment()" style="color: inherit" (click)="bookExfreight()"
            nz-tooltip [nzTooltipTitle]="getExfreightTooltip()">
            <img src="assets/img/exfreight.png" style="width: 14px; height: 20px" />
          </a>
          <div>
            <i nz-icon class="clickable f16 left5 right10" nzTheme="outline" nzType="setting"
            *ngIf="!isOrderfulFTL() && canRouteManual()" nz-tooltip
            nzTooltipTitle="Manual schedule" (click)="onBtnManualRouteShipment()"></i>
          </div>
          <div *ngIf="isLTLMultiPickupDropoff">
            <span class="clickable f14 regular right10" (click)="onBtnCloneOrder()">
              <span nz-icon nzType="copy" nzTheme="outline" nz-tooltip nzTooltipTitle="Clone order"></span>
            </span>
          </div>
          <div class="flex1">
          </div>
          <div>
            <ng-container *ngIf="requirePermissions([PermissionCode.job.onHold])">
              <button nz-button *ngIf="!needReview && !isOnHold" (click)="onPutOnHoldBtn()" nzDanger>
                <i *ngIf="onProgress" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
                Put on Hold
              </button>
            </ng-container>

            <ng-container *ngIf="requirePermissions([PermissionCode.job.unHold])">
              <button nz-button *ngIf="isOnHold" (click)="onUnOnHoldBtn()" nzType="text">
                <i *ngIf="onProgress" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
                Un-Hold
              </button>
            </ng-container>
            <span *ngIf="isOnHold" class="on-hold">On Hold</span>
          </div>
        </div>
        <div class="flex top15 f16m">
          <div style="width: 10px;"></div>
          <nz-table #nzTable [nzData]="shipments" [nzShowPagination]="false" class="flex1">
            <tbody>
              <tr *ngFor="let item of nzTable.data">
                <td style="width: 240px;">
                  <div class="flex">
                    <ng-container *ngIf="!!item?.code">
                      Shipment: <div class="warp-id left5"><code>{{item.code}}</code><br/>({{showShipmentWarpId(item)}})</div>
                    </ng-container>
                    <ng-container *ngIf="!item?.code">
                      Shipment: <div class="warp-id left5">{{showShipmentWarpId(item)}}</div>
                    </ng-container>
                    <div class="left10 f13"><copy-text text="{{ getTrackingLink(item) }}" tooltip="Copy tracking URL"></copy-text></div>
                  </div>
                </td>
                <td style="width: 240px;">
                  <div class="order-status {{ 'status-' + item.status }}" [ngClass]="{'clickable': !isAdminReadOnlyRole}" (click)="onBtnEditShipmentStatus(item)">
                    {{getStatusShipment(item.status)}}
                    <i [ngClass]="{'icon-hidden': isAdminReadOnlyRole}" nz-icon nzType="edit"></i>
                  </div>
                </td>
                <td style="width: 120px;">
                  <a *ngIf="item.bolInfo" (click)="downloadBol(item, $event)">
                    <i *ngIf="!item.bolInfo.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline" class="right5"></i>
                    <i *ngIf="item.bolInfo.isDownloading" nz-icon nzType="loading" nzTheme="outline" class="right5"></i>
                    BOL
                  </a>
                </td>
                <ng-container *ngIf="isBookExfreight(item)">
                  <td style="width: 120px;">
                    <a (click)="onClickQuoteExfreight(item)">
                      <img src="assets/img/exfreight.png" style="width: 14px; height: 20px" />
                      Book Exfresso
                    </a>
                  </td>
                </ng-container>
                <td>
                  <span><i nz-icon nzType="redo" nzTheme="outline" nz-tooltip="Re generate BOL" class="right10 clickable" (click)="regenBOL(item)"></i></span>
                  <span *ngIf="item.externalId && item.status!=='canceled'" third-party-info-icon [shipment]="item" class="right10" (onUpdate)="onUpdated()"></span>
                  <span *ngIf="!item.externalId && !item.legIds?.length" third-party-quote-icon [shipment]="item" (onBooked)="onBooked(item, $event)" class="right10"></span>
                  <!-- <span *ngIf="!item.legIds?.length" warehouse-task-setting-icon [shipment]="item" (onUpdated)="onUpdated()"></span> -->
                  <span *ngIf="showEvents" class="right10" (click)="showShipmentEventHistory(item)">
                    <span nz-icon nzType="compass" nzTheme="outline"></span>
                  </span>
                </td>
                <td>
                  <div *ngIf="isShipmentStorage(item)">
                    <div>Storage: <span *ngIf="item.cost?.grandTotal">${{ item.cost.grandTotal }}</span></div>
                    <div *ngIf="item.lastJobId" class="classification-setting">
                      Linked Cost:
                      <a target="_blank" [routerLink]="[routeAdminDispatchList, item.lastJobId]"><i nz-icon nzType="rocket" nzTheme="outline"></i></a>
                    </div>
                  </div>
                  <div class="flex" *ngIf="isShipmentFTL(item)">
                    <div class="classification-setting">
                      <div class="flex" style="align-items: center;">
                        <div>Product Type: {{ getClassificationSetting(item)?.productType || 'N/A'}}</div>
                        <div>
                          <button class="btn-update" nz-button nzType="link" nzSize="small" [nzLoading]="isUpdateShipmentClassification(item?.id)"
                            (click)="onBtnUpdateClassification(item)">Update</button>
                        </div>
                      </div>
                      <div class="flex" style="align-items: center;">
                        <div>Shipment Classification: {{ getClassificationSetting(item)?.classificationShipment || 'N/A'}}</div>
                        <div>
                          <button class="btn-update" nz-button nzType="link" nzSize="small" [nzLoading]="isUpdateShipmentClassification(item?.id)"
                            (click)="onBtnUpdateClassification(item)">Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="model?.basicInfo?.isCrossDock" class="dashboard-child-container bg-white bottom20" style="padding-bottom: 10px;">
    <div class="f18 font-semibold bottom10">Transit Network</div>
    <ng-container *ngFor="let shipment of listOfShipmentForTransitNetwork">
      <div *ngIf="shipments.length > 1">
        Shipment:
        <span class="warp-id left5"><code>{{shipment.code}}</code></span>
        <span class="warp-id left5">({{showShipmentWarpId(shipment)}})</span>
        <span class="left5"><copy-text text="{{ getTrackingLink(shipment) }}"></copy-text></span>
      </div>
      <div transit-network [id]="shipment.id" [model]="modelForTransitNetwork" (refreshDetailOrder)="onBtnRefreshForTransitNetwork()"></div>
    </ng-container>
  </div>

  <div class="dashboard-child-container bg-white no-padding">
    <div *ngIf="isSingleMode">
      <shipment-entry-single-detail [model]="model" (refreshDetailOrder)="onBtnRefresh()"></shipment-entry-single-detail>
    </div>
    <div *ngIf="!isSingleMode">
      <shipment-entry-batch-detail [model]="model" (refreshDetailOrder)="onBtnRefresh()"></shipment-entry-batch-detail>
    </div>
  </div>
</div>



